import anime from 'animejs/lib/anime.es.js'
import http from './http'

export default {
  install (Vue) {
    // 动画
    Vue.prototype.$anime = anime
    // 请求
    Vue.prototype.$http = http
  }
}
