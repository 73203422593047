<template>
  <div class="about">
    <div id="top"></div>
    <div class="banner"></div>
    <div id="0"></div>
    <div class="tab">
      <div class="tab-wrap">
        <div
          class="tab-item"
          :class="tabIndex === index ? 'active' : ''"
          v-for="(item, index) in tabList"
          v-bind:key="index"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 公司介绍 S -->
    <div class="interview">
      <h1>公司简介</h1>
      <p>福建自驾游商业管理服务有限公司成立于2016年5月。</p>
      <p>注册资本1亿元,总股本1亿股。</p>
      <p>公司是福建省旅游协会自驾游分会会长单位。</p>
      <p>
        公司经营范围：自驾游管理服务、市场调研、自驾游信息咨询、旅游文化活动策划、生态旅游开发、会议会展服务。
      </p>
      <p >
        公司在省旅游协会自驾游专业委员会指导下，整合国内自驾游优势资源，在政府支持下搭建自驾游运营平台，是一家跨行业
      </p>
      <p id="1">联动、跨地区运作的全产业链智慧型自驾游管理专业服务公司。</p>
    </div>
    <!-- 公司介绍 E -->

    <!-- 创始人介绍 S -->
    <div class="csr-interview">
      <div class="csr-bg"></div>
      <div>
        <h1>楚政</h1>
        <p>公司创始人兼CEO</p>
        <div>
          <p>中国自驾游产业联合会秘书长</p>
          <p>全国工商联旅游商会自驾游与房车露营分会常务副会长兼秘书长</p>
          <p>华东六省一市自驾游联盟秘书长</p>
          <p>福建省旅游协会自驾游分会会长</p>
          <p>国家旅游局专家库成员</p>
          <p>福建省旅游专家委员会委员</p>
          <p id="2">
            《中华人民共和国旅游行业标准—自驾游管理服务规范标准》制定人之一。
          </p>
        </div>
      </div>
    </div>
    <!-- 创始人介绍 E -->

    <!-- 联系我们 S -->
    <div class="contact-us">
      <div class="wrap">
        <div class="title">
          <h1>联系我们</h1>
          <div class="en">CONTACT US</div>
        </div>

        <div class="map-wrap">
          <el-amap
            class="amap-box"
            :vid="'amap-vue'"
            :center="center"
            :zoom="zoom"
          >
            <el-amap-marker
              :position="[119.340762, 26.05264]"
              :icon="require('../assets/images/maker.png')"
              :offset="[-15, -15]"
            />
          </el-amap>
          <div class="detail">
            <div class="sf-bg"></div>
            <div class="address">
              <img src="../assets/images/icon_location.png" alt="" />
              <p>福建省福州市台江区曙光支路申发大厦2708</p>
            </div>
            <div class="phone">
              <img src="../assets/images/icon_phone.png" alt="" />
              <p>0591-88060591</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 E -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      tabList: [
        { name: "公司简介" },
        { name: "创始人介绍" },
        { name: "联系我们" },
      ],
      center: [119.340762, 26.05264],
      zoom: 17,
    };
  },
  mounted() {
    let query = this.$route.query.to;
    if (query) {
        this.moveTo(query)
    } else {
        this.moveTo('top')
    }
  },
  watch: {
    $route() {
      let query = this.$route.query.to;
      if (query) {
          this.moveTo(query)
      } else {
          this.moveTo('top')
      }
    },
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
     this.moveTo(index)
    },
      moveTo (id) {
          document
              .getElementById(id)
              .scrollIntoView({ block: "start", behavior: "smooth" });
      }
  },
};
</script>

<style lang="less" scoped>
.about {
  .banner {
    width: 100%;
    height: 550px;
    background: url("../assets/images/about_bg.png") no-repeat center;
    background-size: cover;
  }

  .tab {
    border-bottom: 1px solid #eeeeee;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  .tab-wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 82px;
    align-items: center;

    .tab-item {
      color: #000000;
      font-size: 24px;
      margin-right: 49px;
      transition: color 0.3s;
    }

    .tab-item.active {
      color: #1d83ff;
      position: relative;
    }

    .tab-item.active:after {
      content: "";
      display: block;
      width: 95px;
      height: 3px;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #71a7ff;
    }

    .tab-item:hover {
      color: #1d83ff;
      cursor: pointer;
    }
  }

  /* 公司简介 S */
  .interview {
    color: #000;
    padding-top: 75px;
    margin-bottom: 140px;

    h1 {
      font-size: 30px;
      padding-bottom: 42px;
    }

    p {
      font-size: 16px;
      line-height: 2rem;
    }
  }
  /* 公司简介 E */

  /* 创始人介绍 S */
  .csr-interview {
    width: 1200px;
    margin: 0 auto 93px;
    display: flex;
    align-items: center;
    color: #000;

    .csr-bg {
      width: 612px;
      height: 454px;
      background: url("../assets/images/chuzheng.png") no-repeat center;
      background-size: cover;
      margin-right: 34px;
    }

    h1 {
      font-size: 30px;
      margin-bottom: 7px;
    }

    > p {
      font-size: 18px;
    }

    div div {
      padding-top: 61px;

      p {
        line-height: 2rem;
      }
    }
  }
  /* 创始人介绍 E */

  /* 联系我们 S */
  .contact-us {
    width: 100%;

    .wrap {
      width: 1200px;
      margin: 0 auto 85px;

      .title {
        color: #000;
        margin-bottom: 48px;
        h1 {
          font-size: 30px;
          font-weight: 400;
          margin-bottom: 9px;
        }

        .en {
          font-size: 14px;
          position: relative;
        }

        .en:before,
        .en:after {
          content: "";
          display: block;
          height: 1px;
          width: 567px;
          position: absolute;
          top: 50%;
          background-color: #eeeeee;
        }

        .en:before {
          left: -18px;
        }

        .en:after {
          right: -18px;
        }
      }
    }

    .map-wrap {
      width: 1125px;
      margin: 0 auto;
      height: 544px;
      padding: 15px;
      border: 1px dashed #737373;
      position: relative;

      .detail {
        position: absolute;
        left: 68px;
        top: 108px;
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 3px 3px 0px rgba(6, 0, 1, 0.2);
        padding: 20px;
        text-align: left;
        font-style: 14px;

        .sf-bg {
          width: 340px;
          height: 185px;
          background: url("../assets/images/sf_bg.png") no-repeat center;
          background-size: cover;
          margin-bottom: 31px;
        }

        .address {
          display: flex;
          align-items: center;
          margin-bottom: 28px;
          img {
            width: 13px;
            height: 19px;
            margin-right: 20px;
          }
        }

        .phone {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  /* 联系我们 E */
}
</style>
