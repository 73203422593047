<template>
    <div class="footer" id="footer">
        <div class="top">
            <div class="wrap">
                <ul>
                    <li><div class="first" @click="$router.push('/').catch(err=>err)">首页</div></li>
                    <li>
                        <div class="first"  @click="$router.push('/life').catch(err=>err)">自驾生活</div>
                        <div @click="toLife(1)">活动回顾</div>
                        <div @click="toLife(2)">自驾攻略</div>
                        <div @click="toLife(3)">自驾事项</div>
                        <!--<a class="first" href="">自驾生活</a>-->
                        <!--<a href="">活动回顾</a>-->
                        <!--<a href="">自驾攻略</a>-->
                        <!--<a href="">自驾事项</a>-->
                    </li>
                    <li><div class="first" @click="$router.push('/news').catch(err=>err)">行业动态</div></li>
                    <li>
                        <div class="first" @click="$router.push('/about').catch(err=>err)">关于我们</div>
                        <div @click="toAbout(0)">公司介绍</div>
                        <div @click="toAbout(1)">创始人介绍</div>
                        <div @click="toAbout(2)">联系我们</div>
                        <!--<a class="first" href="/about">关于我们</a>-->
                        <!--<a href="">公司介绍</a>-->
                        <!--<a href="">创始人介绍</a>-->
                        <!--<a href="">联系我们</a>-->
                    </li>
                </ul>
                <div class="qrcode-wrap">
                    <img class="qrcode" src="../assets/qrcode.png" alt="">
                    <p>扫一扫关注我们</p>
                </div>
            </div>
        </div>
        <div class="internet-content-provider">
            <div class="wrap">
                Copyright 2021 福建小熊自驾数字科技有限责任公司. All Rights Reserved. <a class="beian" href="https://beian.miit.gov.cn">闽ICP备2021012765号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            toLife (index) {
                this.$router.push({
                    path: '/life',
                    query: {
                        tabCurrent: index
                    }
                }).catch(err=>err)
            },
            toAbout (index) {
                this.$router.push({
                    path: '/about',
                    query: {
                        to: index
                    }
                }).catch(err=>err)
            }
        }
    }
</script>

<style lang="less" scoped>
    .footer {
        width: 100%;
        .top {
            background-color: #F2F2F2;
            padding: 40px 0;
            .wrap {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
            }

            ul {
                display: flex;

                li {
                    margin-right: 44px;

                    div {
                        display: block;
                        text-align: left;
                        font-size: 14px;
                        color: #000;
                        margin-bottom: 10px;
                    }

                    div:hover {
                        cursor: pointer;
                    }

                    .first {
                        color: #010101;
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                }
            }

            .qrcode-wrap {
                display: flex;
                align-items: center;

                .qrcode {
                    width: 119px;
                    height: 119px;
                    margin-right: 8px;
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                }
            }
        }

        .internet-content-provider {
            width: 100%;
            background-color: #fff;
            height: 99px;

            .wrap {
                line-height: 99px;
                font-size: 16px;
                color: #000;
                width: 1200px;
                margin: 0 auto;
                text-align: center;
            }

        }
    }
    .beian{
      text-decoration: underline;
    }
</style>
