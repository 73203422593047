import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue' // 首页
import About from '../views/About.vue' // 关于我们
import Life from '../views/Life.vue' // 自驾生活
import News from '../views/News.vue' // 行业动态
import Detail from '../views/Detail.vue' // 详情页
import Download from '../views/Download.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/life',
    name: 'Life',
    component: Life,
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
    {
        path: '/download',
        name: 'Download',
        component: Download
    }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
