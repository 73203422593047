<template>
  <div>
    <div>
      <div class="top-background"></div>
      <div class="center-box" id="tab">
        <ul class="tabs">
          <li class="active">行业动态</li>
        </ul>
      </div>
    </div>
    <div class="content-box">
      <div class="content">
        <div
          class="detail"
          v-for="(item, index) in list"
          v-bind:key="index"
          @click="toDetail(item.id)"
        >
          <img :src="item.url" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.createTime.slice(0, 10) }}</div>
        </div>
      </div>
      <div
        class="load-more"
        :class="{ loadActive: active == true }"
        @click="loadMore"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        v-show="showLoadMore"
      >
        加载更多
      </div>
    </div>
    <to-top></to-top>
  </div>
</template>

<script>
import toTop from "@/components/toTop.vue";
export default {
  data() {
    return {
      active: false,
      list: [],
      pageNum: 1,
      rowsNum: 24,
      showLoadMore: true, //是否显示加载更多按钮
      total: null, //列表总数量
    };
  },
  components: {
    toTop,
  },
  methods: {
    loadMore() {
      console.log(this.list.length, "this.list.length");
      if (this.list.length >= 24 * this.pageNum) {
        this.pageNum = this.pageNum + 1;
        this.getList();
      }
    },
    mouseOver() {
      this.active = true;
    },
    mouseLeave() {
      this.active = false;
    },
    async getList() {
      let params = {
        page: this.pageNum,
        rows: this.rowsNum,
      };
      this.$http.fetch("/web/industryNews/getList", params).then((data) => {
        if (data.code === 200) {
          let list = data.obj.rows;
          this.total = data.obj.total;
          this.list = this.list.concat(list);
          console.log(this.list, "list");
        }
      });
    },
    toDetail(id) {
      this.$router.push({ path: "/detail", query: { newsId: id } });
    },
  },
  mounted() {
    this.getList();
  },
  updated() {
    console.log(this.total, "this.total");
    console.log(24 * this.pageNum, "24 * this.pageNum");
    if (this.total <= 24 * this.pageNum) {
      this.showLoadMore = false;
    }
  },
};
</script>

<style lang="less" scoped>
.top-background {
  width: 100%;
  height: 550px;
  background: url("../assets/images/news_bg.png") no-repeat center;
  background-size: cover;
}
.center-box {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding: 28px 0 30px;
}

.tabs {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  li {
    margin-right: 51px;
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    line-height: 21px;
    cursor: pointer;
  }

  .active {
    color: #1d83ff;
  }

  .active::after {
    width: 95px;
    height: 3px;
    display: block;
    background-color: #71a7ff;
    transform: translateY(30px);
    content: "";
  }
}

.content-box {
  width: 100%;
  // padding-bottom: 51px;
  padding: 51px 0;
}

.content {
  width: 1222px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .detail {
    margin-right: 22px;
    margin-bottom: 49px;
    cursor: pointer;

    img {
      width: 385px;
      height: 220px;
    }

    .title {
      width: 385px;
      height: 67px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 24px;
      color: #000000;
      padding-top: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      position: relative;
      text-align: left;
    }

    .time {
      padding-top: 20px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #a5a5a5;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.load-more {
  width: 100px;
  height: 100px;
  border: 1px solid #000000;
  border-radius: 50%;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loadActive {
  background: #000000;
  color: #ffffff;
}
</style>
