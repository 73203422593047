<template>
  <div>
    <div id="banner"></div>
    <div class="top-background"></div>
    <div class="center-box" id="tab">
      <ul class="tabs">
        <li :class="{ active: tabCurrent == 1 }" @click="tabClick(1)">
          活动回顾
        </li>
        <li :class="{ active: tabCurrent == 2 }" @click="tabClick(2)">
          自驾攻略
        </li>
        <li :class="{ active: tabCurrent == 3 }" @click="tabClick(3)">
          自驾事项
        </li>
      </ul>
    </div>
    <div class="content-box">
      <div class="content">
        <div
          v-show="tabCurrent == 1"
          class="detail"
          v-for="(item, index) in activityList"
          v-bind:key="index"
          @click="toDetail(item.id)"
        >
          <img :src="item.url" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.createTime.slice(0, 10) }}</div>
        </div>
        <div
          v-show="tabCurrent == 2"
          class="detail"
          v-for="(item, index) in introductionList"
          v-bind:key="index"
          @click="toDetail(item.id)"
        >
          <img :src="item.url" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.createTime.slice(0, 10) }}</div>
        </div>
        <div
          v-show="tabCurrent == 3"
          class="detail"
          v-for="(item, index) in matterList"
          v-bind:key="index"
          @click="toDetail(item.id)"
        >
          <img :src="item.url" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.createTime.slice(0, 10) }}</div>
        </div>
      </div>
      <div
        class="load-more"
        :class="{ loadActive: active == true }"
        @click="loadMore"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        v-show="showLoadMore"
      >
        加载更多
      </div>
    </div>
    <to-top></to-top>
  </div>
</template>

<script>
import toTop from "@/components/toTop.vue";
export default {
  data() {
    return {
      tabCurrent: 1, //1为活动回顾，2为自驾攻略，3为自驾事项
      active: false,
      list: [],
      activityList: [], //活动回顾列表
      introductionList: [],
      matterList: [],
      showLoadMore: true, //是否显示加载更多按钮
      rowsNum: 24,
      pageNum1: 1, //活动回顾的页面数
      pageNum2: 1,
      pageNum3: 1,
      flag_1: 0, //判断活动回顾的第一页是否调用过接口
      flag_2: 0,
      flag_3: 0,
      total1: null, //活动回顾列表总数量
      total2: null,
      total3: null,
    };
  },
  components: {
    toTop,
  },
  watch: {
    $route() {
      console.log("bian");
      if (this.$route.query.tabCurrent) {
        this.tabCurrent = this.$route.query.tabCurrent;
        this.tabClick(this.$route.query.tabCurrent);
        document
          .getElementById("tab")
          .scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        this.tabClick(this.tabCurrent);
        document
          .getElementById("banner")
          .scrollIntoView({ block: "start", behavior: "smooth" });
      }
      console.log(this.tabCurrent, "111");
    },
  },
  methods: {
    //点击切换自驾生活的小模块
    tabClick(val) {
      this.tabCurrent = val;
      //点击后只让列表页加载一次，避免多次点击造成内容重复拼接到列表
      if (val == 1 && this.flag_1 == 0) {
        this.flag_1 = 1;
        this.getActivityList();
      } else if (val == 2 && this.flag_2 == 0) {
        this.flag_2 = 1;
        this.getIntroductionList();
      } else if (val == 3 && this.flag_3 == 0) {
        this.flag_3 = 1;
        this.getMatterList();
      }
      //判断加载更多按钮是否显示
      setTimeout(() => {
        this.showLoadMore = true;
        if (this.tabCurrent == 1) {
          if (this.total1 <= 24 * this.pageNum1) {
            this.showLoadMore = false;
          }
        } else if (this.tabCurrent == 2) {
          if (this.total2 <= 24 * this.pageNum2) {
            this.showLoadMore = false;
          }
        } else {
          if (this.total3 <= 24 * this.pageNum3) {
            this.showLoadMore = false;
          }
        }
      }, 500);
    },
    //加载更多
    loadMore() {
      if (this.tabCurrent == 1) {
        if (this.activityList.length >= 24 * this.pageNum1) {
          this.pageNum1 = this.pageNum1 + 1;
          this.getActivityList();
        }
      } else if (this.tabCurrent == 2) {
        if (this.introductionList.length >= 24 * this.pageNum2) {
          this.pageNum2 = this.pageNum2 + 1;
          this.getIntroductionList();
        }
      } else {
        if (this.matterList.length >= 24 * this.pageNum3) {
          this.pageNum3 = this.pageNum3 + 1;
          this.getMatterList();
        }
      }
    },
    //鼠标移入
    mouseOver() {
      this.active = true;
    },
    //鼠标移开
    mouseLeave() {
      this.active = false;
    },
    //获取活动回顾列表
    async getActivityList() {
      let params = {
        page: this.pageNum1,
        rows: this.rowsNum,
      };
      this.$http.fetch("/web/activeHistory/getList", params).then((data) => {
        if (data.code === 200) {
          let activityList = data.obj.rows;
          this.total1 = data.obj.total;
          this.activityList = this.activityList.concat(activityList);
          console.log(this.activityList, "activityList");
        }
      });
    },
    //获取自驾攻略列表
    async getIntroductionList() {
      let params = {
        page: this.pageNum2,
        rows: this.rowsNum,
      };
      this.$http
        .fetch("/web/selfDrivingIntroduction/getList", params)
        .then((data) => {
          if (data.code === 200) {
            let introductionList = data.obj.rows;
            this.total2 = data.obj.total;
            this.introductionList =
              this.introductionList.concat(introductionList);
            console.log(this.introductionList, "introductionList");
          }
        });
    },
    //获取自驾事项列表
    async getMatterList() {
      let params = {
        page: this.pageNum3,
        rows: this.rowsNum,
      };
      this.$http
        .fetch("/web/selfDrivingMatter/getList", params)
        .then((data) => {
          if (data.code === 200) {
            let matterList = data.obj.rows;
            this.total3 = data.obj.total;
            this.matterList = this.matterList.concat(matterList);
            console.log(this.matterList, "matterList");
          }
        });
    },
    //跳转详情页
    toDetail(id) {
      if (this.tabCurrent == 1) {
        this.$router.push({ path: "/detail", query: { activityId: id } });
      } else if (this.tabCurrent == 2) {
        this.$router.push({ path: "/detail", query: { introductionId: id } });
      } else {
        this.$router.push({ path: "/detail", query: { matterId: id } });
      }
    },
  },
  mounted() {
    // this.getActivityList();
    // this.flag_1 = 1;
    if (this.$route.query.tabCurrent) {
      this.tabCurrent = this.$route.query.tabCurrent;
      this.tabClick(this.$route.query.tabCurrent);
    } else {
      console.log("111111111111111");
      this.tabClick(this.tabCurrent);
      document
        .getElementById("banner")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
  created() {},
  updated() {
    if (this.tabCurrent == 1) {
      if (this.total1 <= 24 * this.pageNum1) {
        this.showLoadMore = false;
      }
    } else if (this.tabCurrent == 2) {
      if (this.total2 <= 24 * this.pageNum2) {
        this.showLoadMore = false;
      }
    } else {
      if (this.total3 <= 24 * this.pageNum3) {
        this.showLoadMore = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.top-background {
  width: 100%;
  height: 550px;
  background: url("../assets/images/life_bg.png") no-repeat;
  background-size: 100% 100%;
}

.center-box {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding: 28px 0 30px;
}

.tabs {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  li {
    margin-right: 51px;
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    line-height: 21px;
    cursor: pointer;
  }

  li:hover {
    transition: color 0.3s;
    color: #1d83ff;
  }

  .active {
    color: #1d83ff;
  }

  .active::after {
    width: 95px;
    height: 3px;
    display: block;
    background-color: #71a7ff;
    transform: translateY(30px);
    content: "";
  }
}

.content-box {
  width: 100%;
  padding: 51px 0;
}

.content {
  width: 1222px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .detail {
    margin-right: 22px;
    margin-bottom: 49px;
    cursor: pointer;

    img {
      width: 385px;
      height: 220px;
    }

    .title {
      width: 385px;
      height: 67px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 24px;
      color: #000000;
      padding-top: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      position: relative;
      text-align: left;
    }

    .time {
      padding-top: 20px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #a5a5a5;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.load-more {
  width: 100px;
  height: 100px;
  border: 1px solid #000000;
  border-radius: 50%;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loadActive {
  background: #000000;
  color: #ffffff;
}
</style>
