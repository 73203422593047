import Vue from 'vue'
import App from './App.vue'
import router from './router'
import prototype from './utils/prototype' // 挂载在原型上的方法
import axios from 'axios'
import VueAMap from 'vue-amap'

//全局CSS
import '@/style/main.less'


Vue.use(prototype)
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'y77e88c0838cefbd8822b092983700966',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});


/*
 * axios设置
 * */
// axios.defaults.baseURL = 'http://8.136.156.92:8088' // 测试
 axios.defaults.baseURL = 'http://www.xiaoxiongzijia.com' // 华为云正式
// axios.defaults.baseURL = 'http://8.136.156.92:86' // 正式
// axios.defaults.baseURL = 'http://localhost:8083' // zhuzuyao

// 请求超时时限
axios.defaults.timeout = 180000
// 请求的间隙
axios.defaults.retryDelay = 1000

Vue.config.productionTip = false

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
