import axios from 'axios'
import qs from 'qs'
// import Vue from 'vue'

// const vue = new Vue()

// 请求次数
let retry = 1
/* 添加响应拦截器 */
axios.interceptors.response.use(function (response) {
  /* 对响应数据做些事 */
  return response
}, function (error) {
  /* 请求错误时做些事 */
  // 请求超时的之后，抛出 error.code = ECONNABORTED的错误..错误信息是 timeout of  xxx ms exceeded
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    var config = error.config
    config.__retryCount = config.__retryCount || 0

    if (config.__retryCount >= retry) {
      console.log(config.__retryCount, 'config.__retryCount')
      // Reject with the error
      //   vue.$message({
      //       showClose: true,
      //       message: '请求超时',
      //       type: 'error'
      //   })
        console.log('请求超时')
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          confirm.close()
        })

      return Promise.reject(error)
    }

    // Increase the retry count
    config.__retryCount += 1

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })

    return backoff.then(function () {
      return axios(config)
    })
  } else {
    return Promise.reject(error)
  }
})

export default {
  fetch (path, params, contentType) {
    if (!(params instanceof FormData)) {
      if (!contentType) {
        contentType = 'application/x-www-form-urlencoded'
        params = qs.stringify({
          ...params
        })
      }
    }
    return axios.post(path, params, {
      headers: {
        'Content-Type': contentType
      }
    }).then(function (req) {
      let data = req.data
      if (data.code && data.code !== 200) {
        // vue.$message({
        //   showClose: true,
        //   message: data.message,
        //   type: 'error'
        // })
          console.log(data.message)
          return Promise.reject(data)
      }
      return data
    }).catch((err) => {
      return err
    })
  }
}
