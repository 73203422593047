<template>
  <div class="download">
    <button id="button" @click="download">下载</button>

  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
      download () {
          // alert(123)
          window.open('itms-services://?action=download-manifest&url=https://test.xiaoxiongzijia.com/appdown/info', '_self')
      }
  },
};
</script>

<style lang="less" scoped>
  .download {
    height: 300px;
    padding-top: 200px;
  }
</style>
