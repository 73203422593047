<template>
  <div class="home">
    <div class="video-wrap">
      <video
        ref="bannerVideo"
        class="banner-video"
        autoplay
        loop
        muted
        playsinline
        src="http://www.xiaoxiongzijia.com/image/%E7%BD%91%E9%A1%B5.m4v"
      ></video>
    </div>

    <!-- 公司简介 S -->
    <div class="interview">
      <div class="wrap">
        <div class="left-bg"></div>
        <div class="content">
          <div class="title">
            <h1>公司简介</h1>
            <p>Company Profile</p>
            <div class="line"></div>
          </div>
          <p>
            福建自驾游商业管理服务有限公司成立于2016年5月。注册资本1亿元,总股本1亿股。
          </p>
          <p>公司是福建省旅游协会自驾游分会会长单位。</p>
          <p>
            公司经营范围：自驾游管理服务、市场调研、自驾游信息咨询、旅游文化活动策划、生态旅游开发、会议会展服务。
          </p>
          <p>
            公司在省旅游协会自驾游专业委员会指导下，整合国内自驾游优势资源，在政府支持下搭建自驾游运营平台，是一家跨行业联动、跨地区运作的全产业链智慧型自驾游管理专业服务公司。
          </p>
        </div>
      </div>
    </div>
    <!-- 公司简介 E -->

    <!-- 行业动态 S -->
    <div class="dongtai">
      <div class="title">行业动态</div>

      <div class="swiper-wrap">
        <Swiper class="swiper" ref="dongtaiSwiper" :options="swiperOptions">
          <swiper-slide
            class="item-box"
            v-for="(item, index) in industryNews"
            v-bind:key="index"
          >
            <div
              class="left-bg"
              :style="'background: url(' + item.url + ') no-repeat center'"
            ></div>
            <div class="content">
              <h1>{{ item.title }}</h1>
              <div class="check-more-btn" data-name="more-btn" :data-id="item.id">
                查看更多
              </div>
            </div>
          </swiper-slide>
          <div class="btn-prev" slot="button-prev"></div>
          <div class="btn-next" slot="button-next"></div>
        </Swiper>
      </div>
    </div>
    <!-- 行业动态 E -->

    <!-- 活动回顾 S -->
    <div class="active-history">
      <div class="title">活动回顾</div>
      <Swiper
        class="swiper"
        ref="activeHistorySwiper"
        :options="swiperOptions2"
      >
        <swiper-slide
          class="item-box"
          v-for="(item, index) in activeHistory"
          v-bind:key="index"
        >
          <div
            class="item-bg"
            :style="'background: url(' + item.url + ') no-repeat center'"
            @click="toDetail(item.id, 2)"
          ></div>
          <div class="item-title">{{ item.title }}</div>
          <div class="time">{{ item.createTime }}</div>
        </swiper-slide>
        <div class="btn-prev" slot="button-prev">
          <div class="white-opacity"></div>
          <div class="btn"></div>
        </div>
        <div class="btn-next" slot="button-next">
          <div class="white-opacity"></div>
          <div class="btn"></div>
        </div>
      </Swiper>
      <a
        href="javascript:;;"
        class="check-more"
        @click="$router.push('/life').catch((err) => err)"
        >查看更多</a
      >
    </div>
    <!-- 活动回顾 E -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Home",
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".btn-prev",
          prevEl: ".btn-next",
        },
        loop: true,
          on: {
            click:  (e) => {
                const name = e.target.dataset.name
                const id = e.target.dataset.id
                if (name === 'more-btn') {
                    this.$router.push({ path: "/detail", query: { newsId: id } });
                }
            }
          }
      },
      swiperOptions2: {
        initialSlide: 2,
        navigation: {
          nextEl: ".btn-next",
          prevEl: ".btn-prev",
        },
        slidesPerView: 2,
        loop: true,
      },
      activeHistory: [],
      industryNews: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    dongtaiSwiper() {
      return this.$refs.dongtaiSwiper.$swiper;
    },
  },
  mounted() {
    this.dongtaiSwiper.slideTo(3, 1000, false);
    this.getDongtai();
  },
  methods: {
    getDongtai() {
      let params = {};
      this.$http.fetch("/web/index/getIndex", params).then((data) => {
        if (data.code === 200) {
          this.activeHistory = data.obj.activeHistory;
          this.industryNews = data.obj.industryNews;
        }
      });
    },
    toDetail(id, type) {
      console.log(id, type);
      if (type == 1) {
        this.$router.push({ path: "/detail", query: { newsId: id } });
      } else {
        this.$router.push({ path: "/detail", query: { activityId: id } });
      }
    },
    test() {
      console.log(123);
    },
  },
};
</script>

<style lang="less" scoped>
/*@import 'swiper/swiper-bundle.css'*/
.home {
  width: 100%;
}
.video-wrap {
  position: relative;
  width: 100%;
  height: 700px;
  .banner-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
}

/* 公司简介 S */
.interview {
  background-color: #f2f2f2;
  padding: 70px 0;
  .wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-color: #fff;

    .left-bg {
      width: 638px;
      height: 440px;
      background: url("../assets/images/interview_bg.png") no-repeat center;
      background-size: cover;
    }

    .content {
      flex: 1;
      background-color: #fff;
      text-align: left;
      padding: 32px 61px 46px 53px;
      box-sizing: border-box;

      .title {
        color: #1d83ff;
        h1 {
          font-size: 30px;
          font-weight: 500;
        }
        p {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .line {
          width: 77px;
          height: 1px;
          background-color: #bfbfbf;
          margin-bottom: 20px;
        }
      }

      > p {
        font-size: 16px;
        text-indent: 2em;
        color: #000;
        line-height: 2rem;
      }
    }
  }
}
/* 公司简介 E */

/* 行业动态 S */
.dongtai {
  padding: 60px 0 80px 0;

  .swiper-wrap {
    .swiper {
      border-top: 1px solid #eeeeee;
    }

    .btn-prev {
      width: 46px;
      height: 46px;
      background: url("../assets/images/pre.png") no-repeat center;
      background-size: cover;
      position: absolute;
      right: 56px;
      top: 10px;
      z-index: 1;
    }

    .btn-next {
      width: 46px;
      height: 46px;
      background: url("../assets/images/next.png") no-repeat center;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 10px;
      z-index: 1;
    }

    .btn-prev:hover {
      cursor: pointer;
      background-image: url("../assets/images/pre_gray.png");
    }

    .btn-next:hover {
      cursor: pointer;
      background-image: url("../assets/images/next_gray.png");
    }

    .swiper {
      width: 1200px;
      height: 480px;

      .item-box {
        display: flex;

        .left-bg {
          width: 760px;
          height: 480px;
          background-size: cover !important;
        }

        .content {
          flex: 1;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 26px;
          text-align: left;

          h1 {
            color: #1d83ff;
            font-size: 22px;
          }

          .check-more-btn {
            // width: 100px;
            // height: 100px;
            // background-colorgb(65, 46, 46)000;
            position: absolute;
            bottom: 0;
            color: #1d83ff;
            border-bottom: 1px solid #1d83ff;
            padding-bottom: 5px;
            z-index: 999999;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* 行业动态 E */

/* 活动回顾 S */
.active-history {
  width: 100%;
  overflow: hidden;
  padding-bottom: 74px;

  .swiper {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    overflow: initial;

    .item-box {
      text-align: left;

      .item-bg {
        width: 510px;
        height: 290px;
        background-size: cover;
        margin-bottom: 24px;
      }

      .item-title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 510px;
      }

      .time {
        color: #a5a5a5;
        font-size: 14px;
        display: none;
      }
    }

    .item-box:hover {
      cursor: pointer;
    }

    .btn-prev,
    .btn-next {
      position: absolute;
      top: 0;
      width: 389px;
      height: 292px;
      z-index: 2;

      .white-opacity {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #fff;
        opacity: 0.7;
        left: 0;
        top: 0;
        z-index: 3;
      }

      .btn {
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        z-index: 4;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .btn-prev {
      left: -389px;
      right: auto;

      .btn {
        right: -35px;
        background: url("../assets/images/pre_blue.png") #fff no-repeat center;
        background-size: 12px 20px;
      }
    }

    .btn-next {
      right: -389px;
      left: auto;

      .btn {
        left: -35px;
        background: url("../assets/images/next_blue.png") #fff no-repeat center;
        background-size: 12px 20px;
      }
    }

    .btn-prev:hover,
    .btn-next:hover {
      cursor: pointer;
    }

    .swiper-slide-active,
    .swiper-slide-next {
      .item-title {
        display: block;
      }
      .time {
        display: block;
      }
    }
  }

  .check-more {
    color: #626262;
    font-size: 18px;
    width: 180px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a0a0a0;
    margin: 49px auto 0;
  }
}
/* 活动回顾 E */

.dongtai .title,
.active-history .title {
  font-family: "BenmoChenhei";
  font-style: italic;
  color: #000;
  font-size: 30px;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  margin-bottom: 60px;
}

.dongtai .title:before,
.dongtai .title:after,
.active-history .title:before,
.active-history .title:after {
  content: "";
  display: block;
  width: 97px;
  height: 1px;
  background-color: #a5a5a5;
  position: absolute;
  top: 50%;
}

.dongtai .title:before,
.active-history .title:before {
  left: -130px;
}

.dongtai .title:after,
.active-history .title:after {
  right: -130px;
}
</style>
