<template>
  <div>
    <div class="title">{{ detailList.title }}</div>
    <div class="time">{{ detailList.createTime.slice(0, 10) }}</div>
    <p v-html="detailList.content" class="content"></p>
    <to-top></to-top>
  </div>
</template>

<script>
import toTop from "@/components/toTop.vue";
export default {
  data() {
    return {
      newsId: null,
      activityId: null,
      introductionId: null,
      matterId: null,
      detailList: {},
    };
  },
  components: {
    toTop,
  },
  methods: {
    getDetail() {
      if (this.newsId) {
        let params = {
          id: this.newsId,
        };
        this.$http.fetch("/web/industryNews/getById", params).then((data) => {
          if (data.code === 200) {
            this.detailList = data.obj;
          }
        });
      } else if (this.activityId) {
        let params = {
          id: this.activityId,
        };
        this.$http.fetch("/web/activeHistory/getById", params).then((data) => {
          if (data.code === 200) {
            this.detailList = data.obj;
          }
        });
      } else if (this.introductionId) {
        let params = {
          id: this.introductionId,
        };
        this.$http
          .fetch("/web/selfDrivingIntroduction/getById", params)
          .then((data) => {
            if (data.code === 200) {
              this.detailList = data.obj;
            }
          });
      } else if (this.matterId) {
        let params = {
          id: this.matterId,
        };
        this.$http
          .fetch("/web/selfDrivingMatter/getById", params)
          .then((data) => {
            if (data.code === 200) {
              this.detailList = data.obj;
            }
          });
      }
    },
  },
  mounted() {
    this.getDetail();
    // this.$router.back();
  },
  created() {
    if (this.$route.query.newsId) {
      this.newsId = this.$route.query.newsId;
    } else if (this.$route.query.activityId) {
      this.activityId = this.$route.query.activityId;
    } else if (this.$route.query.introductionId) {
      this.introductionId = this.$route.query.introductionId;
    } else if (this.$route.query.matterId) {
      this.matterId = this.$route.query.matterId;
    }

    console.log(this.id, "id");
  },
  //   destoryed() {
  //     this.$router.back();
  //   },
};
</script>

<style lang="less" scoped>
.title {
  width: 898px;
  margin: 175px auto 19px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 36px;
}
.time {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  margin-bottom: 40px;
}
.content {
  width: 898px;
  margin: 0 auto 102px;
}
</style>