<template>
  <nav :class="navClass">
    <div class="nav-inner">
      <h1 class="logo">
        <a href="/"></a>
      </h1>
      <div class="action-box">
        <ul class="menu" @mouseenter="expandMenu" @mouseleave="shrinkMenu">
          <li
            class="menu-item"
            @mouseenter="expandSubMenu(0)"
            @mouseleave="shrinkSubMenu(0)"
          >
            <!--<a href="/">首页</a>-->
            <div
              class="title"
              :class="active === 0 ? 'active' : ''"
              @click="$router.push('/').catch((err) => err)"
            >
              首页
            </div>
          </li>
          <li
            class="menu-item"
            @mouseenter="expandSubMenu(1)"
            @mouseleave="shrinkSubMenu(1)"
          >
            <!--<a href="/life">自驾生活</a>-->
            <div
              class="title"
              :class="active === 1 ? 'active' : ''"
              @click="$router.push('/life').catch((err) => err)"
            >
              自驾生活
            </div>
            <transition @enter="subMenuShowAnime" @leave="subMenuHideAnime">
              <ul v-show="currentMenuIndex == 1" class="sub-menu">
                <li class="sub-menu-item">
                  <!--<a :href="navigation(1)">活动回顾</a>-->
                  <div @click="navigation(1)">活动回顾</div>
                </li>
                <li class="sub-menu-item">
                  <!--<a :href="navigation(2)">自驾攻略</a>-->
                  <div @click="navigation(2)">自驾攻略</div>
                </li>
                <li class="sub-menu-item">
                  <!--<a :href="navigation(3)">自驾事项</a>-->
                  <div @click="navigation(3)">自驾事项</div>
                </li>
              </ul>
            </transition>
            <div v-show="expand" class="menu-item-trigger"></div>
          </li>
          <li
            class="menu-item"
            @mouseenter="expandSubMenu(2)"
            @mouseleave="shrinkSubMenu(2)"
          >
            <!--<a href="/news">行业动态</a>-->
            <div
              class="title"
              :class="active === 2 ? 'active' : ''"
              @click="$router.push('/news').catch((err) => err)"
            >
              行业动态
            </div>
          </li>
          <li
            class="menu-item"
            @mouseenter="expandSubMenu(3)"
            @mouseleave="shrinkSubMenu(3)"
          >
            <!--<a href="/about">关于我们</a>-->
            <div
              class="title"
              :class="active === 3 ? 'active' : ''"
              @click="$router.push('/about').catch((err) => err)"
            >
              关于我们
            </div>
            <transition @enter="subMenuShowAnime" @leave="subMenuHideAnime">
              <ul v-show="currentMenuIndex == 3" class="sub-menu">
                <li class="sub-menu-item">
                  <!--<a href="/about?to=0">公司介绍</a>-->
                  <div @click="toAbout(0)">公司介绍</div>
                </li>
                <li class="sub-menu-item">
                  <!--<a href="/about?to=1">创始人介绍</a>-->
                  <div @click="toAbout(1)">创始人介绍</div>
                </li>
                <li class="sub-menu-item">
                  <!--<a href="/about?to=2">联系我们</a>-->
                  <div @click="toAbout(2)">联系我们</div>
                </li>
              </ul>
            </transition>
            <div v-show="expand" class="menu-item-trigger"></div>
          </li>
        </ul>
      </div>
    </div>
    <transition @enter="maskShowAnime" @leave="maskHideAnime">
      <div
        v-show="expand && (currentMenuIndex == 1 || currentMenuIndex == 3)"
        class="menu-expand-mask"
      ></div>
    </transition>
  </nav>
</template>

<script>
import util from "@/lib/util";

export default {
  data() {
    return {
      expand: false,
      currentMenuIndex: -1,
      scrollDirection: "down",
      pageScroll: 0,
      menuPanelShow: false,
      active: 0,
      path: "",
    };
  },
  computed: {
    navClass() {
      if (this.pageScroll < 30) {
        // if(this.expand) {
        //     return 'white-nav white-nav-expand'
        // }
        // else {
        return "transparent-nav";
        // }
      } else if (this.pageScroll >= 30 && this.pageScroll < 50) {
        return "white-nav";
      } else {
        if (this.scrollDirection == "down") {
          return "white-nav hide-nav";
        } else {
          return "white-nav";
        }
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // console.log('改变')
        this.setActive()
      },
    },
  },
  created() {
      this.setActive()
      this.handleScroll();
  },
  methods: {
      setActive () {
          // 获取当前路径
          this.path = this.$route.path;
          // console.log(this.path, ' this.path')
          if (this.path.indexOf("life") !== -1) {
              this.active = 1;
          } else if (this.path.indexOf("news") !== -1) {
              this.active = 2;
          } else if (this.path.indexOf("about") !== -1) {
              this.active = 3;
          } else {
              this.active = 0;
          }
      },
    handleScroll() {
      window.addEventListener("scroll", () => {
        let scrollTop = util.getPageScrollY();
        if (scrollTop > this.pageScroll) {
          this.scrollDirection = "down";
        } else {
          this.scrollDirection = "up";
        }

        this.pageScroll = scrollTop;
      });
    },
    expandMenu() {
      this.expand = true;
    },
    shrinkMenu() {
      this.expand = false;
    },
    expandSubMenu(index) {
      this.currentMenuIndex = index;
    },
    shrinkSubMenu() {
      this.currentMenuIndex = -1;
    },
    maskShowAnime(el, done) {
      this.$anime({
        targets: el,
        scaleY: [0, 1],
        opacity: [0, 1],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    maskHideAnime(el, done) {
      this.$anime({
        targets: el,
        scaleY: [1, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    subMenuShowAnime(el, done) {
      this.$anime({
        targets: el,
        translateY: [-30, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    subMenuHideAnime(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, -30],
        opacity: [1, 0],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    menuPanelShowAnime(el, done) {
      this.$anime({
        targets: el,
        translateX: [-260, 0],
        easing: "linear",
        duration: 250,
        complete() {
          done();
        },
      });
    },
    navigation(val) {
      this.$router
        .push({
          path: "/life",
          query: {
            tabCurrent: val,
          },
        })
        .catch((err) => err);
    },
    toAbout(index) {
      this.$router
        .push({
          path: "/about",
          query: {
            to: index,
          },
        })
        .catch((err) => err);
    },
  },
};
</script>

<style lang="less" scoped>
nav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  height: 100px;
  transition: all 0.3s;
}

.nav-inner {
  width: 1200px;
  height: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  /*@media screen and (max-width: 1200px) {*/
  /*width: 100%;*/
  /*padding: 0px 24px;*/
  /*}*/
}

.logo {
  width: 195px;
  height: 55px;

  a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0px;
    background-size: 100% 100%;
  }
}

.action-box {
  /*width: 520px;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;

  @media screen and (max-width: 1200px) {
    padding-right: 0;
  }
}

.menu {
  height: 100%;
  display: flex;
}

.menu-item {
  height: 100%;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .title {
    opacity: 0.9;
    position: relative;
    font-size: 18px;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: -8px;
      left: 0px;
      background-color: #1d83ff;
      opacity: 0;
      transform-origin: 50% 0%;
      transform: scaleX(0);
      transition: all 0.2s;
    }
  }

  .title.active {
    color: #1d83ff !important;
  }
}

.menu-item:hover > a::after {
  opacity: 1;
  transform: scaleX(1);
}

.menu-item-trigger {
  width: 100%;
  height: 18.12vw;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 1;
}

.sub-menu {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 2;
}

/*.sub-menu-item {*/
/*width: 100%;*/
/*padding: 0.72vw 0px;*/
/*text-align: center;*/
/*cursor: pointer;*/

/*div {*/
/*color: #2b2e2e;*/
/*opacity: 0.7;*/
/*font-size: 16px;*/
/*}*/
/*}*/

/*.sub-menu-item > div:hover {*/
/*opacity: 1;*/
/*}*/

.menu-expand-mask {
  width: 100%;
  height: 260px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  transform-origin: 50% 0%;
}

.lang-box {
  a {
    opacity: 0.7;
  }

  i {
    font-size: 0.6vw;
    opacity: 0.7;
    margin: 0px 3px;
  }

  .current {
    opacity: 1;
  }
}

.white-nav {
  background-color: white;

  .logo {
    a {
      background-image: url("../assets/logo.png");
    }
  }

  .menu-item > .title {
    color: #010101;
  }

  .lang-box {
    color: #2b2e2e;
  }
}

.white-nav-expand {
  background-color: transparent;
}

.transparent-nav {
  background-color: #fff;

  .logo {
    a {
      background-image: url("../assets/logo.png");
    }
  }

  .menu-item > .title {
    color: #010101;
  }

  .lang-box {
    color: white;
  }
}

.hide-nav {
  top: -100px;
}

.panel-lang-bnav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
  height: 5.2vw;
  transition: all 0.3s;
}

.action-box {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;

  @media screen and (max-width: 1200px) {
    padding-right: 0;
  }
}

.menu {
  height: 100%;
  display: flex;
}

.menu-item:hover > .title::after {
  opacity: 1;
  transform: scaleX(1);
}

.sub-menu {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 2;
}

.sub-menu-item {
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;

  div {
    color: #a5a5a5;
    font-size: 16px;
  }
}

.sub-menu-item > div:hover {
  color: #000;
}

.lang-box {
  a {
    opacity: 0.7;
  }

  i {
    font-size: 0.6vw;
    opacity: 0.7;
    margin: 0px 3px;
  }

  .current {
    opacity: 1;
  }
}

.white-nav {
  background-color: white;

  .logo {
    a {
      background-image: url("../assets/logo.png");
    }
  }

  .menu-item > a {
    color: #010101;
  }

  .lang-box {
    color: #2b2e2e;
  }
}

.white-nav-expand {
  background-color: transparent;
}

.transparent-nav {
  background-color: #fff;

  .logo {
    a {
      background-image: url("../assets/logo.png");
    }
  }

  .menu-item > a {
    color: #010101;
  }

  .lang-box {
    color: white;
  }
}
</style>
