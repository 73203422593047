<template>
  <div id="app">
    <nav-bar></nav-bar>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/common/navBar.vue";
import Footer from "@/common/footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: max-content;
  min-width: -moz-max-content;
}
</style>
